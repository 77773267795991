import logo from './logo.png';
import header from './assets/header.png';
// import sticky from './assets/sticky.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import twitterLogo from './assets/twitter-logo.svg';


function App() {

  // function sayHello() {
  //   alert('Not so fast! Coming soon.');
  // }

  return (
    <div className="App">
      {/* <Header /> */}
      
      <header className="App-header">
        {/* <img src={sticky} className="Sticky-img" alt="sticky" /> */}
        <img src={header} className="Header-img" alt="Giga" />
        <img src={logo} className="App-logo" alt="pepe" />
        <button
          className="cta-button-grey connect-wallet-button"
          // onClick={sayHello}
        >
          Connect to Wallet
        </button>
        <form>
          <button type="submit"
              className="cta-button join-button"
              formAction='https://discord.gg/wTRXPwnZwu'
              >
                Join Discord 👾
            </button>
        </form>
          {/*<button
            className="cta-button whitepaper-button"
            onClick={null}
            >
              Whitepaper
          </button> */}
          <form>
            <button type="submit"
              className="cta-button buy-button"
              formAction='https://trade.dexlab.space/#/market/BakCv2Eu94YvwHnxgU1A3eE8DZgrcKNcvbVu1p2Qqzmu'
              >
                Buy $GIGA
            </button>
          </form>
        {/* <div className="Card-Container">
          <button
            className="cta-button roadmap-button"
            onClick={null}
            >
              Roadmap
          </button>
          <button
            className="cta-button whitepaper-button"
            onClick={null}
            >
              Whitepaper
          </button>
          <button
            className="cta-button buy-button"
            onClick={null}
            >
              Buy $GIGA
          </button>
        </div> */}

        {/* <Container>
            <Accordion flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                    <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                    cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                    <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                    cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                    est laborum.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container> */}       
        <div className="footer-container">
          <img alt="Twitter Logo" className="twitter-logo" src={twitterLogo} />
          <a
            className="footer-text"
            href="https://twitter.com/giga3labs"
            target="_blank"
            rel="noreferrer"
          >{'Built by Gigas, for Gigas.'}</a>
        </div>  
      </header>
      
    </div>
  );
}

export default App;
